@import "~bootstrap/scss/bootstrap";

$blue: #0071CE;
$darkblue:#003057;
$grey: #A5A5A5;

@font-face {
  font-family: 'dinopro';
  src: url('../fonts/DIN-Pro-400.eot');
  src: url('../fonts/DIN-Pro-400.eot?#iefix') format('embedded-opentype'),
      url('../fonts/DIN-Pro-400.woff2') format('woff2'),
      url('../fonts/DIN-Pro-400.woff') format('woff'),
      url('../fonts/DIN-Pro-400.ttf') format('truetype'),
      url('../fonts/DIN-Pro-400.svg#dinopro') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'dinopro-bold';
  src: url('../fonts/DIN-Pro-Bold-700.eot');
  src: url('../fonts/DIN-Pro-Bold-700.eot?#iefix') format('embedded-opentype'),
      url('../fonts/DIN-Pro-Bold-700.woff2') format('woff2'),
      url('../fonts/DIN-Pro-Bold-700.woff') format('woff'),
      url('../fonts/DIN-Pro-Bold-700.ttf') format('truetype'),
      url('../fonts/DIN-Pro-Bold-700.svg#dinopro-bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @-ms-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animation($animationName) {
  -moz-animation: #{$animationName};
  -o-animation: #{$animationName};
  -webkit-animation: #{$animationName};
  -ms-animation: #{$animationName};
  animation: #{$animationName};
}

@mixin transformmix($animationName) {
  -webkit-transform: #{$animationName};
  -moz-transform: #{$animationName};
  -ms-transform: #{$animationName};
  -o-transform: #{$animationName};
  transform: #{$animationName};
}

@keyframes spinner {
  100%{
    @include transformmix("rotate(360deg)");
  }
}

@import 'partials/utility';
@import 'partials/form';
@import 'partials/croppie';
@import 'partials/base';

@media screen and (max-width: 767px){
  @import 'partials/767dwn';
}

@media screen and (min-width: 768px){
  @import 'partials/768up';
}
 