#form-wrapper{
    background: #fff;
    max-width: 894px;
    padding: 0 15px 30px;
    padding-top: 30px;

    .bottom-form{
        margin-top: 30px;
        border-top: 2px solid #0071CE;
        padding-top: 50px;
    }
}


.btn{
    font-size: 1rem;
    line-height: 1;
    font-family: 'dinopro-bold';
    text-transform: uppercase;
    color: #fff;
    background: $darkblue;
    border-radius: 10px;
    max-width: 360px;
    line-height: 1.2;
    border: none;
    text-decoration: none;
    cursor: pointer;
    padding: 18px 38px;
    min-width: 180px;
    transition: all 0.25s ease-in-out;
    position: relative;
    &:hover, &:focus, &:active{
        background: lighten($darkblue, 10%);
        color: #fff !important;
    }

    &.lb{
        background: $blue !important;
        color: #fff !important;
        &:hover, &:focus, &:active{
            background: darken($blue, 10%) !important;
        }
    }

    &.loading{
        padding-right: 60px;
        &:after{
            content: '';
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            right: 20px;
            width: 24px;
            height: 24px;
            margin-top: -12px;
            border-radius: 50%;
            border: 3px solid #fff;
            border-top-color: #000;
            @include animation( 'spinner .8s linear infinite' );
            z-index: 21;
        }
    }
}

#error-bucket, #success-bucket{
    padding: 20px;
    border-radius: 10px;
}
#error-bucket{
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
    ul{
        margin: 0;
        list-style-type: disc;
        padding-left: 20px;
    }
    margin-bottom: 30px;
}

#success-bucket{
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
    margin-bottom: 30px;
}

form{
    .form-control, .form-select{
        height: 64px;
        border: 2px solid #0071CE;
        padding: 10px 20px;
        line-height: 44px;
        width: 100%;
    }
    fieldset{
        margin: 0;
        padding: 0;
        border: none;
    }

    textarea{
        min-height: 100px !important;
    }

    span.error{
        display: block;
        color: #842029;
        background-color: #f8d7da;
        border-color: #f5c2c7;
        border-radius: 10px;
        text-align: left;
        padding: 10px 20px;
        margin-top: 15px;
    }

    .form-group{
        position: relative;
        &.inline{
            display: flex;
            .btn{
                margin-left: 20px;
                min-width: 220px;
            }
        }
    }

    #file-chosen{
        font-family: "dinopro-bold";
        font-size: 0.8rem;
    }

    .field-input{
        margin-bottom: 20px;
        width: 100%;
        span.error{
            color: red;
            margin-top: 10px;
            display: block;
        }
        label{
            display: block;
            margin-bottom: 10px;
        }
    }
    textarea{
        min-height: 88px;
    }
    select{
        border-radius: 0;
        padding: 4px 10px !important;
    }
    .form-radio, .form-check{
        position: relative;
        margin-bottom: 20px;
        input{
            position: absolute;
            opacity: 0;
        }
        .form-label{
            position: relative;
            display: block;
            padding-left: 52px;
            cursor: pointer;
            &:before, &:after{
                content: '';
                position: absolute;
                left: 0;
            }
            &:before{
                width: 32px;
                height: 32px;
                border: 2px solid #0071CE;
                top: -5px;
            }
        }
        
    }
    .form-radio .form-label:before{
        border-radius: 22px;
    }
    .form-radio, .form-check{
        padding-left: 0 !important;
        input:checked + .form-label:after{
            width: 18px;
            height: 18px;
            background: #0071CE;
            border-radius: 50%;
            left: 7px;
            top: 3px;
        }
    }
    .form-check{
        margin-bottom: 36px !important;
        input:checked + .form-label:after{
            border-radius: 0;
        }
    }

    .file-input{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding-top: 10px;
        label{
            font-size: 1.3rem;
            font-family: 'dinopro-bold';
            color: #0071CE;
            border: 2px solid #0071CE;
            border-radius: 50px;
            text-transform: uppercase;
            line-height: 1;
            padding: 10px 20px;
            cursor: pointer;
        }
        > span{
            display: block;
            margin-top: 10px;
            width: 100%;
            text-align: center;
        }
    }
}

p.question{
    @extend .huge-text;
    margin: 0 0 20px;
}

.col2{
    > div{
        width: 100%;
        input[type="text"], input[type="email"], select, textarea{
            width: 100%;
        }
    }
}