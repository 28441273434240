h2{
    font-size: 2.35rem;
}


.col2{
    display: flex;
    justify-content: space-between;
    > div{
        width: calc(50% - 10px);
    }
}

.modal-title{
    font-size: 2.35rem;
}

.enforce-img-height{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    > img{
        min-width: 700px;
    }
}

.row.reverse-md{
    flex-direction: row-reverse;
}