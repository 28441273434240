html{
    font-size: 20px;
}

body{
    background: $blue;
    font-family: 'dinopro';
    color: #ffffff;
    font-size: 1rem;
    line-height: 24px;
    min-width: 320px;
    line-height: 1.2;
}
h1, h2, h3, h4, h5, h6, strong{
    font-family: 'dinopro-bold';
}

h2{
    font-size: 2rem;
}

.sz-25{
    font-size: 1.25rem;
}

.self-center{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.container{
    max-width: 1140px !important;
}

#main-content{
    width: 100%;
    overflow: hidden;
}

.bbg{
    background: $blue;
}

ol, ul{
    list-style: none;
}
p{
    margin-top: 0;
}

.img-resp{
    width: 100%;
    height: auto;
}
.db-text{
    color: $darkblue;
}
.list-tile{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &.white-bg{
        > li{
            background: #fff;
            border-radius: 10px;
            padding: 20px;
        }
    }
    > li{
        padding: 10px;
        min-width: 380px;
        max-width: 380px;
        div.icon{
            img{
                margin: 0 auto;
            }
        }
        
        color: $darkblue;
        margin: 0 10px 20px;
        @media screen and (min-width: 768px){
            padding: 20px 30px;
            width: calc(33.33333% - 20px);
            max-width: 315px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
.has-bubble-patterns{
    overflow: hidden;
    position: relative;
    > div{
        position: relative;
        z-index: 2;
    }
    &:before, &:after{
        content: '';
        position: absolute;
        background-size: contain !important;
        z-index: 1;
        width: 50%;
        padding-bottom: 50%;
    }
    &:before{
        background: url('../images/bl_pattern.png') no-repeat bottom left;
        max-width: 327px;
        max-height: 326px;
        bottom: 0;
        left: 0;
    }
    &:after{
        background: url('../images/tr_pattern.png') no-repeat top right;
        max-width: 452px;
        max-height: 460px;
        top: 0;
        right: 0;
    }
}

#hero-section{
    overflow: hidden;
    background: url('../images/hero_bg.jpg') no-repeat top center;
    background-size: cover;
    padding-top: 40px;

    @media screen and (min-width: 768px){
        max-height: 790px;

        .col-12{
            &:first-of-type{
                img{
                    margin-left: -14.2%;
                }
            }
            &:last-of-type{
                img{
                    margin-right: -14.2%;
                }
            }
        }
    }
}

.join-section{
    background: url('../images/lab_bg.jpg') no-repeat top center;
    background-size: cover;
}

#search-results ul li{
    > strong{
        margin-bottom: 4px;
    }
    &:not(:last-of-type){
        margin-bottom: 15px;
    }
}

#zip-results, 
#search-results{
    margin-top: 40px;
    @extend .self-center;
    max-width: 600px;
    .no-results{
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 540px;
        @extend .self-center;
        a{
            color: #fff;
        }
        p{
            margin: 0;
        }
    }
    &.loading{
        min-height: 300px;
        position: relative;
        &:before{
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 150px;
            height: 150px;
            margin: -75px 0 0 -75px;
            background: url('../images/loader_wht.gif') no-repeat center center;
        }
    }
    .item{
        background: $darkblue;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        padding: 20px;
        margin-bottom: 20px;
        &:last-of-type{
            margin-bottom: 0;
        }
        &.full{
            background: $grey;
            flex-wrap: wrap;
            .info{
                margin-bottom: 10px;
            }
            > div:last-of-type{
                font-size: 0.9rem;
                strong{
                    display: block;
                };
            }
        }
        .cta{
            span{
                text-transform: uppercase;
                font-family: 'dinopro-bold';
            }
        }
        .info{
            position: relative;
            padding-left: 40px;
            &:before{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 31px;
                height: 38px;
                background: url('../images/marker.png') no-repeat center center;
            }
            .name{
                font-family: 'dinopro-bold';
                display: block;
                margin-bottom: 8px;
            }
            .address{
                font-size: 0.9rem;
            }
        }
        .btn{
            position: relative;
            &:after{
                content: '';
                position: absolute;
                top: 50%;
                margin-top: -14px;
                right: 15px;
                width: 15px;
                height: 27px;
                z-index: 1;
                background: url('../images/arrow.png')  no-repeat right center;
            }
        }

        @media screen and (max-width: 600px){
            flex-wrap: wrap;
            justify-content: flex-start;
            .info{
                width: 100%;
                margin-bottom: 20px;
            }
            .cta{
                .btn{
                    background: $blue;
                    padding-top: 15px !important;
                    padding-bottom: 15px !important;
                    &:after{
                        right: 20px !important;
                    }
                }
            }
        }
    }
}

.modal-title{
    font-size: 2rem;
    line-height: 1;
}
.modal-dialog{
    max-width: 800px !important;
    min-width: 320px !important;
}

.modal-content{
    color: $darkblue;
    .modal-header{
        display: block;
        position: relative;
        padding: 20px 30px;
        .btn-close{
            position: absolute;
            top: 20px;
            right: 20px;
        }
    }
    form{
        .form-control{
            height: 52px;
            line-height: 32px;
        }
    }
}
#volunteer-section, .has-full-bubble{
    position: relative;
    &:before, &:after{
        position: absolute;
        content: '';
        background-size: contain !important;
        z-index: 1;
    }
    > div{
        position: relative;
        z-index: 2;
    }
    &:before{
        // bottom: 0;
        // left: 0;
        // background: url('../images/bl_pattern2.png') no-repeat bottom left;
        // max-width: 327px;
        // max-height: 164px;
        // width: 40%;
        // height: 50%;

        width: 14%;
        padding-bottom: 22%;
        background: url('../images/bl_pattern.png') no-repeat bottom left;
        max-width: 327px;
        max-height: 326px;
        background-size: contain !important;
        bottom: 0;
        left: 0;
    }
    &:after{
        top: 0;
        right: 0;
        background: url('../images/tr_pattern2.png') no-repeat top right;
        max-width: 280px;
        max-height: 437px;
        width: 40%;
        height: 65%; //50%;
        opacity: 0.3;
    }
    .volunteer-tiles-wrapper{
        .tile{
            > img{
                display: block;
                width: 100%;
                height: auto;
            }
            .copy{
                padding: 10px 6px;
            }
        }
    }
}

#picture-filter-wrapper{
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    .file-input-wrapper {
        width: 300px;
        height: 60px;
        overflow: hidden;
        position: relative;
        display: inline-block;
    }

    .file-input-wrapper>input[type=file] {
        font-size: 200px;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        z-index: 3;
        cursor: pointer;
    }
    .img-preview-wrapper {
        display: flex;
        justify-content: center;
        width: 800px;
        height: 800px;
    }

    #img-preview {
        background: url('../images/filter.png') no-repeat;
        background-size: contain !important;
        // padding-bottom: 100%;
        position: relative;
        margin-bottom: 10px;
        // background-position: -157px -9px;
        &.off {
            // height: 0;
            overflow: hidden;
            .cr-boundary{
                > img{
                    opacity: 0 !important;
                }
            }
        }

        .cr-viewport.cr-vp-square {
            left: auto;
            right: 0;
            bottom: auto;
            top: 0;
        }

        .cr-slider-wrap {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 99;
        }
    }

    

    #result-img {
        display: flex;
        justify-content: center;
        @extend .self-center;
        max-width: 400px;
        img{
            width: 100%;
            height: auto;
        }
    }

    #result-img>div {
        padding: 20px;
    }
}