.d-block{
    display: block;
}

.ovh{
    overflow: hidden;
}

.img-fluid{
    max-width: 100%;
    height: auto;
}

.ovh{
    overflow: hidden;
}

.dblue{
    color: $darkblue;
}

ul, ol{
    &.plain{
        margin: 0;
        padding: 0;
    }
}
.lblue{
    color: $blue;
}
.self-center{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.p-0{
    padding: 0 !important;
}
.m-0{
    margin: 0;
}
.mb-0{
    margin-bottom: 0 !important;
}

.dblue-bg{
    background: $darkblue;
}

.mb-10{
    margin-bottom: 10px !important;
}
.mb-20{
    margin-bottom: 20px !important;
}
.mb-30{
    margin-bottom: 30px !important;
}
.mb-40{
    margin-bottom: 40px !important;
}
.px-15{
    padding-left: 15px;
    padding-right: 15px;
}

.red{
    color: #F00F00;
}

.huge-text{
    font-family: 'dinopro-bold';
    font-size: 1.3rem;
    line-height: 34px;
    color: #0071CE;
}

.d-flex{
    display: flex;
}

.jc-center{
    justify-content: center;
}

.text-white{
    color: #fff !important;
}

.text-blue{
    color: $blue !important;
}