.sz-25{font-size: 1.1rem !important;}
form{
    .form-group{
        &.inline{
            flex-wrap: wrap;
            .btn{
                margin: 20px auto;
            }
        }
    }
}